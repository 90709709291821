import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import RedCircle from '../../../../../src/assets/Red_Circle_Left.png';
import Tabs from './tabs';
// import KeyDates from './key-dates';
import LogicalPillars from './logical-pillars';
import CompanyDetails from '../view-response';
const ReviewMyContent = () => {
  const [selectedTab, setSelectedTab] = useState('Questions');
  const tabs = [
    // { name: 'Inputted Plans', href: '#', current: true },
    // { name: 'Key Dates', href: '#', current: false },
    { name: 'Questions', href: '#', current: false },
    { name: 'Company Details', href: '#', current: false }
  ];
  const location = useLocation();

  const contentData = location.state;
  const planId: string = contentData?.planId
    ? contentData?.planId
    : localStorage.getItem('CurrentContentPlanId');
  localStorage.setItem('CurrentContentPlanId', planId);

  const renderSelectedComponent = () => {
    switch (selectedTab) {
      // case 'Inputted Plans':
      //   return <InputtedPlans planId={planId} />;
      case 'Questions':
        return <LogicalPillars planId={planId} />;
      case 'Company Details':
        return <CompanyDetails />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col h-full md:h-fit min-h-full md:min-h-screen lg:min-h-full lg:h-full relative lg:pb-10 items-center">
      <div className="flex flex-col items-center w-full mt-10">
        <p className="text-black text-4xl font-medium mb-3">{'Content plan'}</p>
        <p className="flex justify-center items-center text-center text-black text-4xl md:text-5xl font-extrabold mb-14">
          {'Review my Content?'}
        </p>
        <Tabs
          tabs={tabs}
          onTabClick={(tabName: any) => setSelectedTab(tabName)}
        />
      </div>
      <div
        className={
          selectedTab === 'Company Details' ? 'w-full' : ' max-w-xl w-full'
        }
      >
        {renderSelectedComponent()}
      </div>
      <div className="w-full flex flex-col justify-center">
        {/* <div className="md:absolute right-0 bottom-0 flex justify-center md:justify-end items-center gap-4 mb-5 md:mb-0 mr-0 md:mr-5 w-full">
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Terms and Conditions
          </button>
          <div className="w-px h-[31px] relative bg-pink-400" />
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Privacy Policy
          </button>
        </div> */}
        <img
          className="hidden md:flex absolute w-[220px] h-[200px] lg:w-[330px] lg:h-[300px] right-0 -rotate-90 bottom-0 -z-10"
          src={RedCircle}
          alt={'details circles'}
        />
      </div>
    </div>
  );
};

export default ReviewMyContent;
