import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import DaysLoader from '../../day-of-awareness/component/days-loader';
import RedCircle from '../../../../../assets/Red_Circle_Left.png';
import ClueLogo from '../../../../../assets/Clue-Logo.png';

const ProcessingData = () => {
  const navigate = useNavigate();
  const email = localStorage.getItem('UserEmail');
  const [showLoader, setShowLoader] = useState(true);
  const postId = localStorage.getItem('PostId');
  const planId = localStorage.getItem('PlanId');
  const [posts, setPosts] = useState([]);
  const { state } = useLocation();
  useEffect(() => {
    if (state) {
      setPosts(state);
      setShowLoader(false);
    } else {
      // if not exists in state fetch from backend
      fetchPosts();
    }
  }, []);
  const fetchPosts = () => {
    const bodyData = { email: email, postId: postId, planId: planId };
    fetch(
      `${process.env.REACT_APP_BACKEND_URI}/post/generate-post-with-chatgpt`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyData)
      }
    )
      .then(async (res) => {
        console.log(res);
        const response = await res.json();

        if (res.ok) {
          console.log('Generate Posts Response', response);
          //if no posts are generated refetch(generate posts in backend)
          if (response.posts.length === 0) {
            console.log('no postes');
            setShowLoader(true);
            fetchPosts();
          } else {
            setPosts(response.posts);
            setShowLoader(false);
          }
        }
      })
      .catch((err) => {
        console.log('Error adding user details!', err);
      });
  };
  return (
    <div className="flex flex-col min-h-screen relative lg:pb-10 items-center ">
      <div className="flex flex-col items-center w-full mt-5">
        <img
          className="max-w-[225px] max-h-[82px] mb-14"
          src={ClueLogo}
          alt={'Clue Logo'}
        />
        <p className="text-black text-4xl font-medium mb-3">
          {'Revenue Activity'}
        </p>
        <p className="flex justify-center items-center text-center text-black text-4xl md:text-5xl font-extrabold mb-14">
          {'Generating [....]?'}
        </p>
      </div>
      {showLoader ? <DaysLoader screen="processing-data" /> : ''}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full max-w-2xl lg:max-w-4xl z-50 gap-8 mb-16 px-5 md:px-0">
        {posts.map((post: any, index) => (
          <div
            key={index}
            className="col-span-1 flex flex-col justify-center items-center shadow-xl p-5 border border-gray-100 rounded-lg bg-white"
          >
            <p className="w-full text-black text-[26px] font-semibold">
              Sales post
            </p>
            <p className="text-black text-sm font-normal">{post.post}</p>
            <div className="flex justify-between items-center w-full mt-16">
              <button
                className="w-16 h-10 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 text-center text-neutral-800 text-sm font-medium"
                onClick={() => {
                  navigate('/edit-post', {
                    state: { post: post.post, posts: posts, postId: post.id }
                  });
                }}
              >
                Edit
              </button>
              <button
                className="w-16 h-10 bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium"
                onClick={() => {
                  navigate('/review-post');
                }}
              >
                Review
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="flex w-full justify-center items-center gap-5">
        <button
          className="flex justify-center items-center  mt-10 px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold mb-5"
          onClick={() => {
            navigate('/revenue-activity');
          }}
        >
          {'Back'}
        </button>
      </div>
      <div className="w-full flex flex-col justify-center">
        <img
          className="hidden md:flex absolute w-[220px] h-[200px] lg:w-[330px] lg:h-[300px] left-0 bottom-0 -z-10"
          src={RedCircle}
          alt={'details circles'}
        />
        <div className="md:absolute bottom-2 right-10 justify-center md:justify-end items-center gap-4 mb-5 md:mb-0 inline-flex w-full">
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Terms and Conditions
          </button>
          <div className="w-px h-[31px] relative bg-pink-400" />
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Privacy Policy
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProcessingData;
