export const getEngagementRate = (data: any, platform: string) => {
  try {
    const enagagementRateBodyData: any = {
      num_followers: data.followers ? data.followers : 500,
      data: []
    };
    const posts: any = [];
    const engagementRateUrl = 'https://ai.cluetech.co.uk/multi_score';
    data.posts.map((post: any) => {
      posts.push({
        caption: post.caption,
        platform: platform,
        taken_at: post?.taken_at,
        num_likes: post.like_count,
        num_comments: post.comment_count,
        num_reshares: 0
      });
    });

    enagagementRateBodyData.data = posts;
    console.log('first', posts);
    return fetch(engagementRateUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(enagagementRateBodyData)
    })
      .then(async (response) => {
        const res = await response.json();

        return res.engagement_scores.map((rate: number) => {
          return Math.ceil(100 * rate);
        });
      })
      .catch((err) => {
        console.log('Error fetching multi score', err);
      });
  } catch (error) {}
};

export const getEngagementRateForTikTok = (data: []) => {
  try {
    const engagmentRates: any = [];
    data.map((post: any, index) => {
      //handle post.like_count should not be 0 to avoid infinity

      console.log(
        post.number_of_comments +
          post.number_of_hearts +
          post.number_of_reposts +
          post.number_of_saves
      );
      const engagementRate =
        ((post.number_of_comments +
          post.number_of_hearts +
          post.number_of_reposts +
          post.number_of_saves) /
          (post.number_of_plays === 0 ? 1 : post.number_of_plays)) *
        100;

      engagmentRates.push(Math.ceil(engagementRate));
    });
    return engagmentRates;
  } catch (error) {
    console.log('Error generating engagement rate', error);
  }
};
