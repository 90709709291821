import React, { useState, ChangeEvent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import YellowCircle from '../../../../../assets/Yellow-Circle-Left.png';
import ClueLogo from '../../../../../assets/Clue-Logo.png';

const EditPost = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [postData, setPostData] = useState('');
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 300) {
      setPostData(inputValue);
    }
  };
  useEffect(() => {
    setPostData(state?.post);
  }, []);
  const characterLimit = 300;
  const remainingCharacters = characterLimit - postData.length;
  const editPost = () => {
    const updatedPosts = state?.posts.map((post: any) => {
      if (post.id === state.postId) {
        return { id: post.id, post: postData };
      }
      return post;
    });
    console.log('updatedPosts', updatedPosts);

    const planId = localStorage.getItem('PlanId');
    const postId = localStorage.getItem('PostId');
    const bodyData = {
      planId: planId,
      postId: postId,
      posts: updatedPosts,
      lastStep: 'edit-post'
    };
    fetch(`${process.env.REACT_APP_BACKEND_URI}/post/update`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyData)
    })
      .then(async (res) => {
        console.log(res);
        const response = await res.json();
        console.log('User Add Response', response);

        if (res.ok) {
          navigate('/processing-data', { state: updatedPosts });
        }
      })
      .catch((err) => {
        console.log('Error adding user details!', err);
      });
  };
  return (
    <div className="w-full flex flex-col justify-center">
      <div className="lg:flex h-screen w-full relative lg:pb-10">
        <div className="flex flex-col w-full items-center">
          <div className="flex flex-col items-center w-full mt-5">
            <img
              className="max-w-[225px] max-h-[82px] mb-[20px]"
              src={ClueLogo}
              alt={'Clue Logo'}
            />
            <p className="text-black text-4xl font-medium mb-3">
              {'Sales Posts'}
            </p>
            <p className="flex justify-center items-center text-center text-black text-5xl font-extrabold mb-14">
              {'Edit data [....]?'}
            </p>
            <div className="w-full max-w-md px-5 md:px-0">
              <div className="relative">
                <textarea
                  className="relative resize-none w-full px-[25px] h-[150px] pt-5 pb-[15px] rounded-2xl border border-cyan-500 flex-col justify-end items-end gap-[13px] inline-flex mb-5 focus:outline-1 focus:outline-cyan-500 focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500"
                  placeholder="Worem ipsum dolor sit amet, consectetur adipiscing elit."
                  value={postData}
                  onChange={handleInputChange}
                  maxLength={characterLimit}
                />
                <p className="absolute bottom-10 right-2 text-center text-black text-xs font-light">
                  {`${postData.length}/${remainingCharacters}`}
                </p>
              </div>
              <div className="flex w-full justify-center items-center gap-5">
                <button
                  className="flex justify-center items-center  mt-10 px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold mb-5"
                  onClick={() => {
                    navigate('/processing-data');
                  }}
                >
                  {'Back'}
                </button>
                <button
                  className="flex w-full justify-center items-center px-0 lg:px-28 py-3 bg-rose-500 rounded-lg mt-10 text-center text-white text-lg font-bold mb-5"
                  onClick={editPost}
                >
                  {'Select'}
                </button>
              </div>
            </div>
          </div>
        </div>
        <img
          className="hidden md:flex absolute w-[400px] h-[330px] lg:w-[443px] lg:h-[393px] left-0 bottom-0 -z-10"
          src={YellowCircle}
          alt={'details circles'}
        />
        <div className="md:absolute bottom-2 right-10 justify-center md:justify-end items-center gap-4 mb-5 md:mb-0 inline-flex w-full">
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Terms and Conditions
          </button>
          <div className="w-px h-[31px] relative bg-pink-400" />
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Privacy Policy
          </button>
        </div>
      </div>
    </div>
  );
};
export default EditPost;
