import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaperAirplaneIcon, UserIcon } from '@heroicons/react/24/outline';
import React from 'react';
import SignUpCircles from '../../../../assets/Sign-In-Circles.png';
import videoBackground from '../../../../assets/play-video/video_background.png';
import { toast } from 'sonner';

function AskingQuestions() {
  const planId = localStorage.getItem('PlanId');
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [logicalPillars, setLogicalPillars] = useState<any>([]);
  const [answer, setAnswer] = useState('');
  const [answers, setAnswers] = useState<any>([]);
  const [disableNext, setDisableNext] = useState(true);
  const [editedAnswer, setEditedAnswer] = useState('');
  const [editedIndex, setEditedIndex] = useState(null);

  const handleAnswer = (answer: any) => {
    if (!containsOnlySpaces(answer)) {
      setAnswers([
        ...answers,
        {
          id: logicalPillars[currentQuestionIndex].id,
          question: logicalPillars[currentQuestionIndex].question,
          answer: answer,
          questionType: logicalPillars[currentQuestionIndex].questionType
        }
      ]);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      if (
        logicalPillars[currentQuestionIndex].question ===
        'How many times would you like to posts in a week?'
      ) {
        setDisableNext(false);
      }
      setAnswer('');
    }
  };

  const handleEdit = (index: any) => {
    setEditedAnswer(answers[index].answer);
    setEditedIndex(index);
  };

  const handleSave = () => {
    if (editedIndex !== null && editedAnswer.trim() !== '') {
      if (logicalPillars[editedIndex]?.id === 16) {
        if (Number(editedAnswer) >= 1 && Number(editedAnswer) <= 7) {
          const updatedAnswers = [...answers];
          updatedAnswers[editedIndex].answer = editedAnswer;
          setAnswers(updatedAnswers);
          setEditedAnswer('');
          setEditedIndex(null);
        } else {
          toast.error('Please enter a number between 1 and 7');
          setEditedAnswer(answers[editedIndex].answer);
        }
      } else {
        const updatedAnswers = [...answers];
        updatedAnswers[editedIndex].answer = editedAnswer;
        setAnswers(updatedAnswers);
        setEditedAnswer('');
        setEditedIndex(null);
      }
    } else {
      toast.error("Empty answer isn't allowed!");
    }
  };

  useEffect(() => {
    setLogicalPillars([
      {
        id: 1,
        question: 'Tell me about your company?',
        answer: '',
        questionType: 'logical'
      },
      {
        id: 2,
        question: 'Why did you start your company?',
        answer: '',
        questionType: 'logical'
      },
      {
        id: 3,
        question: 'How does your company help people?',
        answer: '',
        questionType: 'logical'
      },
      {
        id: 4,
        question: 'What differentiates your company?',
        answer: '',
        questionType: 'logical'
      },
      {
        id: 5,
        question: 'Share advice with your followers?',
        answer: '',
        questionType: 'logical'
      },
      {
        id: 6,
        question: 'What things get you excited in business?',
        answer: '',
        questionType: 'logical'
      },
      {
        id: 7,
        question: 'Feedback about what you do?',
        answer: '',
        questionType: 'logical'
      },
      {
        id: 8,
        question: 'How does your ideal client feel right now?',
        answer: '',
        questionType: 'logical'
      },
      {
        id: 9,
        question: 'What does your ideal client not know?',
        answer: '',
        questionType: 'logical'
      },
      {
        id: 10,
        question: 'About your company background?',
        answer: '',
        questionType: 'logical'
      },
      {
        id: 11,
        question: 'What are you selling?',
        answer: '',
        questionType: 'sales'
      },
      {
        id: 12,
        question: 'Why are you selling it?',
        answer: '',
        questionType: 'sales'
      },
      {
        id: 13,
        question: 'Who are you selling it to?',
        answer: '',
        questionType: 'sales'
      },
      {
        id: 14,
        question: 'How can people buy from you?',
        answer: '',
        questionType: 'sales'
      },
      {
        id: 15,
        question: 'When people buy fom you?',
        answer: '',
        questionType: 'sales'
      },
      {
        id: 16,
        question: 'How many times would you like to posts in a week?',
        answer: '',
        questionType: 'sales'
      }
    ]);
  }, []);

  const containsOnlySpaces = (str: string) => {
    var regex = /\S/;
    return !regex.test(str);
  };
  const handleNext = async () => {
    const bodyData = {
      planId: planId,
      questions: answers,
      lastStep: 'asking-questions'
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/plan/update`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        }
      );
      console.log('rs', response);
      if (response.ok) {
        const data = await response.json();
        console.log({ data });

        if (data?.code == 200) {
          navigate('/weekly-content-strategy');
        } else {
          throw new Error(data.status);
        }
      } else {
        throw new Error('Unable to connect to Backend');
      }
    } catch (error: any) {
      console.log(error.message);
      console.log(error.msg);
      // Handle network errors or exceptions
      console.error('Error:', error);
    }
  };

  const renderPreviousQuestions = () => {
    return answers.map((entry: any, index: any) => (
      <div className="flex flex-col" key={index}>
        <div className="flex w-full justify-between items-center mt-5 px-4 py-2">
          <div className="flex justify-start items-center space-x-3">
            <img className="w-8 h-8" src={videoBackground} alt="Clue Logo" />
            <p>{entry.question}</p>
          </div>
          <div className="flex flex-col">
            <button
              className=" text-teal-500"
              onClick={() => handleEdit(index)}
            >
              Edit
            </button>
          </div>
        </div>
        <div className="flex w-full justify-between items-center mt-5 border border-teal-500 px-4  bg-slate-100">
          <div className="flex h-24 justify-start items-center space-x-3 w-full ">
            <UserIcon className="w-6 h-6 bg-white border border-teal-500" />
            {editedIndex === index ? (
              <input
                className="flex border-b border-gray-400 px-2 py-4  focus:outline-none  focus:ring-0  w-full"
                value={editedAnswer}
                onChange={(e) => setEditedAnswer(e.target.value)}
              />
            ) : (
              <p className="flex overflow-auto max-h-20 w-full  ">
                Answer: {entry.answer}
              </p>
            )}
          </div>
          {editedIndex === index ? (
            <div>
              <button className=" text-teal-500 ml-2" onClick={handleSave}>
                Save
              </button>
            </div>
          ) : null}
        </div>
      </div>
    ));
  };

  const progressPercentage = Math.floor(
    (currentQuestionIndex / logicalPillars.length) * 100
  );

  return (
    <div className="w-full flex flex-col justify-center">
      <div className="lg:flex h-screen w-full relative lg:pb-10">
        <div className="flex flex-col w-full items-center px-5 md:px-0 mt-10">
          <div className="flex justify-end w-[30%] ">
            <p className="text-right text-sm">{progressPercentage}%</p>
          </div>
          <div className="w-[30%] bg-gray-200  h-3 mt-2">
            <div
              className="bg-green-500 h-3"
              style={{ width: `${progressPercentage}%` }}
            ></div>
          </div>
          <div className="flex flex-col items-center w-full mt-5 p-8 rounded-lg bg-white shadow-xl max-w-4xl max-h-screen overflow-y-auto space">
            <div className="w-full space-y-5">
              <div className="flex flex-col w-full max-w-xl justify-start items-start">
                <p className="flex justify-center items-center text-center text-black text-xl md:text-2xl font-bold">
                  {'Talk to me'}
                </p>
                <p className="text-black text-sm font-normal mb-3 mt-2">
                  {'Explain more.'}
                </p>
              </div>
              <div>{renderPreviousQuestions()}</div>
              {currentQuestionIndex < logicalPillars.length ? (
                <>
                  <div className="flex w-full justify-between items-center px-4 py-2">
                    <div className="flex justify-start items-center space-x-3">
                      <img
                        className="w-8 h-8"
                        src={videoBackground}
                        alt={'Clue Logo'}
                      />
                      <p>{logicalPillars[currentQuestionIndex].question}</p>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}

              {disableNext ? (
                <div className="relative flex justify-center items-center">
                  <textarea
                    className="w-full pl-5 pr-10 relative  bg-white rounded-lg border border-teal-500 mb-5 focus:outline-1 focus:outline-teal-500 justify-center focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
                    placeholder={`${
                      logicalPillars[currentQuestionIndex]?.id === 16
                        ? 'Enter a number between 1 and 7'
                        : 'Enter answer ...'
                    }`}
                    onChange={(e: any) => {
                      setAnswer(e.target.value);
                    }}
                    value={answer}
                    rows={3}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        if (logicalPillars[currentQuestionIndex]?.id === 16) {
                          if (Number(answer) >= 1 && Number(answer) <= 7) {
                            handleAnswer(answer);
                          } else {
                            toast.error(
                              'Please enter a number between 1 and 7'
                            );
                            setAnswer('');
                          }
                        } else {
                          handleAnswer(answer);
                        }
                      }
                    }}
                    disabled={!disableNext}
                    style={{ paddingTop: '5px', paddingBottom: '5px' }}
                  />

                  <button
                    className="absolute top-4 right-3"
                    onClick={() => {
                      if (logicalPillars[currentQuestionIndex]?.id === 16) {
                        if (Number(answer) >= 1 && Number(answer) <= 7) {
                          handleAnswer(answer);
                        } else {
                          toast.error('Please enter a number between 1 and 7');
                          setAnswer('');
                        }
                      } else {
                        handleAnswer(answer);
                      }
                    }}
                  >
                    <PaperAirplaneIcon className="text-teal-500 w-5 h-5 -rotate-45 hover:fill-current" />
                  </button>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="flex w-full justify-end items-center gap-5">
              <button
                className="flex justify-center items-center  mt-10 px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold mb-5"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {'Back'}
              </button>
              <button
                className={`flex w-fit justify-center items-center px-4 lg:px-10 py-3   ${
                  disableNext ? 'bg-rose-400' : 'bg-rose-500'
                } hover:bg-rose-400 rounded-lg mt-10 text-center text-white text-lg font-bold mb-5`}
                onClick={() => {
                  handleNext();
                }}
                disabled={disableNext}
              >
                {'Next'}
              </button>{' '}
              {/* Adjusted alignment */}
            </div>
          </div>
        </div>
        <img
          className="hidden md:flex absolute w-[350px] h-[300px] lg:w-[343px] lg:h-[343px] right-0 -rotate-90 bottom-0 -z-10"
          src={SignUpCircles}
          alt={'details circles'}
        />
        {/* <div className="md:absolute bottom-2 right-10 justify-center md:justify-end items-center gap-4 mb-5 md:mb-0 inline-flex w-full">
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Terms and Conditions
          </button>
          <div className="w-px h-[31px] relative bg-pink-400" />
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Privacy Policy
          </button>
        </div> */}
      </div>
    </div>
  );
}
export default AskingQuestions;
