import { useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

function RequireAuth() {
  const [isApproved, setIsApproved] = useState<boolean | null>(null);
  const loginToken = localStorage.getItem('LoginToken');
  const email = localStorage.getItem('UserEmail');
  let location = useLocation();
  useEffect(() => {
    if (loginToken) {
      fetch(
        `${process.env.REACT_APP_BACKEND_URI}/user/get-profile-data/${email}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async (res) => {
          console.log('fetch profile data response', res);
          if (res.ok) {
            const response = await res.json();
            setIsApproved(response.profileData.isApproved);
          } else {
            setIsApproved(false);
          }
        })
        .catch((err) => {
          console.log('Error fetching profile data', err);
          setIsApproved(false);
        });
    } else {
      setIsApproved(false);
    }
  }, [loginToken, email]);

  if (!loginToken) {
    return <Navigate to="/login" state={{ from: location }} />;
  } else if (isApproved === false) {
    return <Navigate to="/account-verification" state={{ from: location }} />;
  }

  return <Outlet />;
}

export default RequireAuth;
