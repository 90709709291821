import { useEffect, useState } from 'react';
import React from 'react';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import { UserIcon } from '@heroicons/react/24/outline';
import DaysLoader from '../../day-of-awareness/component/days-loader';
import videoBackground from '../../../../../assets/play-video/video_background.png';
import { containsOnlySpaces } from '../../../../../utils';

interface QuestionInterface {
  id: number;
  question: string;
  answer: string;
  questionType: string;
}
interface Props {
  planId: string;
}

const LogicalPillars: React.FC<Props> = ({ planId }) => {
  const navigate = useNavigate();
  const [logicalPillarsData, setLogicalPillars] = useState<QuestionInterface[]>(
    []
  );
  const planIdString = planId;
  const [editedAnswers, setEditedAnswers] = useState<string[]>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [editIndex, setEditIndex] = useState<number | null>(null);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URI}/plan/get-questions/${planIdString}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(async (res) => {
        if (res.ok) {
          const response = await res.json();
          setLogicalPillars(response.questions);
          setEditedAnswers(
            response.questions.map(
              (question: QuestionInterface) => question.answer
            )
          );
          setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log('Error fetching days of awareness', err);
      });
  }, []);

  const handleEditAnswer = (index: number) => {
    setEditIndex(index);
    // Set the initial value of the input field to the current answer
    setEditedAnswers(logicalPillarsData.map((question) => question.answer));
  };

  const handleSaveAnswer = async (index: number) => {
    if (
      editedAnswers[index] &&
      logicalPillarsData[index] &&
      !containsOnlySpaces(editedAnswers[index])
    ) {
      const updatedAnswers = [...logicalPillarsData];
      updatedAnswers[index].answer = editedAnswers[index];
      setLogicalPillars(updatedAnswers);
      saveUpdatedAnswers(updatedAnswers);
      setEditIndex(null); // Reset editIndex after saving
    } else {
      toast.error("Empty answer value isn't accepted!");
    }
  };

  const saveUpdatedAnswers = async (updatedAnswers: QuestionInterface[]) => {
    const bodyData = {
      planId: planIdString,
      questions: updatedAnswers,
      lastStep: 'logical-pillars'
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/plan/update`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        }
      );
      console.log('rs', response);
      if (response.ok) {
        const data = await response.json();
        console.log({ data });

        if (data?.code == 200) {
        } else {
          throw new Error(data.status);
        }
      } else {
        throw new Error('Unable to connect to Backend');
      }
    } catch (error: any) {
      console.log(error.message);
      console.log(error.msg);
      // Handle network errors or exceptions
      console.error('Error:', error);
    }
  };

  return (
    <div className="w-full mt-20 mb-14 px-5 md:px-0">
      <div className="w-full p-5 bg-white shadow-lg">
        <div className="w-full flex justify-between items-center mb-10">
          <p className="text-xl font-bold"></p>
        </div>
        {showLoader && <DaysLoader />}
        {logicalPillarsData.map((entry: QuestionInterface, index: number) => (
          <div className="flex flex-col" key={index}>
            <div className="flex w-full justify-between items-center mt-5 px-4 py-2">
              <div className="flex justify-start items-center space-x-3">
                <img
                  className="w-8 h-8"
                  src={videoBackground}
                  alt={'Clue Logo'}
                />
                <p>{entry.question}</p>
              </div>
              <div className="flex flex-col"></div>
            </div>
            <div className="flex w-full h-24 justify-between items-center mt-5 border border-teal-500 px-4  gap-x-4">
              <div className="flex items-center w-full gap-x-4">
                <UserIcon className="w-8 h-8 p-1 bg-white border border-teal-500" />
                {editIndex === index ? (
                  <input
                    className="flex flex-1 h-24 px-2  focus:outline-none focus:ring-0 w-full "
                    value={editedAnswers[index]}
                    onChange={(e) => {
                      const newEditedAnswers = [...editedAnswers];
                      newEditedAnswers[index] = e.target.value;
                      setEditedAnswers(newEditedAnswers);
                    }}
                  />
                ) : (
                  <p className="flex overflow-auto max-h-20 w-full">
                    Answer: {entry.answer}
                  </p>
                )}
              </div>
              <div>
                {editIndex === index ? (
                  <button
                    className="w-16 h-8 bg-white rounded-lg   hover:bg-gray-100 text-center text-neutral-800 text-sm font-medium"
                    onClick={() => {
                      if (logicalPillarsData[index]?.id === 16) {
                        if (
                          Number(editedAnswers[index]) >= 1 &&
                          Number(editedAnswers[index]) <= 7
                        ) {
                          handleSaveAnswer(index);
                        } else {
                          toast.error('Please enter a number between 1 and 7');
                          const newEditedAnswers = [...editedAnswers];
                          newEditedAnswers[index] = '';
                          setEditedAnswers(newEditedAnswers);
                        }
                      } else {
                        handleSaveAnswer(index);
                      }
                    }}
                  >
                    Save
                  </button>
                ) : (
                  <button
                    className="w-16 h-8  bg-white rounded-lg  border border-gray-200 hover:bg-gray-100 text-center text-neutral-800 text-sm font-medium"
                    onClick={() => handleEditAnswer(index)}
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex w-full justify-center items-center gap-5">
        <button
          className="flex justify-center items-center mt-10 my-8  px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold"
          onClick={() => {
            navigate(-1);
          }}
        >
          {'Back'}
        </button>
      </div>
    </div>
  );
};

export default LogicalPillars;
