import './App.css';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';

function App() {
  // Intentional error: Unused variable 'logo'
  // ESLint should catch this as it's an unused variable
  // Remove or comment out this line to fix the error

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
