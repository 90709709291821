import { useState } from 'react';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import YellowCircle from '../../../../assets/Yellow-Circle-Left.png';

import Website from '../../../../assets//Web-Site.png';
import ViewContent from '../../../../assets//Social-Account.png';
import Brand from '../../../../assets//Brand-Awarness.png';
import Followers from '../../../../assets//Followers.png';
import { updatePlanIDInDb } from './component/UpdatePlanIDInDB';
const CreatePlan = () => {
  const [planSelected, setPlanSelected] = useState(false);
  const [planType, setPlanType] = useState('');
  const navigate = useNavigate();
  const selectPlanType = async () => {
    if (!planSelected) {
      toast.error('Please select plan.');
    } else if (planSelected) {
      const userEmail = localStorage.getItem('UserEmail');
      console.log('creating plan for', userEmail);
      const bodyData = {
        email: userEmail,
        lastStep: 'create-plan'
      };
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URI}/plan/create`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(bodyData)
          }
        );
        if (response.ok) {
          const data = await response.json();
          console.log({ data });

          if (data?.code == 200) {
            localStorage.setItem('PlanId', data.planId);
            const planId = localStorage.getItem('PlanId');
            updatePlanIDInDb(data.planId, userEmail || '');
            const bodyData1 = {
              planId: planId,
              planType: planType,
              lastStep: 'select-plan-type'
            };
            console.log({ bodyData1 });
            try {
              const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URI}/plan/update`,
                {
                  method: 'PATCH',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(bodyData1)
                }
              );
              if (response.ok) {
                console.log({ response });
                navigate('/asking-questions');
              } else {
                throw new Error('Unable to connect to Backend');
              }
            } catch (error: any) {
              toast.error(`${error?.message}`);
              console.log(error.message);
              console.log(error.msg);

              // Handle network errors or exceptions
              console.error('Error:', error);
            }
          } else {
            throw new Error(data.status);
          }
        } else {
          throw new Error('Unable to connect to Backend');
        }
      } catch (error: any) {
        toast.error(`${error?.message}`);
        console.log(error.message);
        console.log(error.msg);

        // Handle network errors or exceptions
        console.error('Error:', error);
      }
    }
  };
  return (
    <div className="w-full h-full md:h-screen lg:h-full bg-red-00 flex flex-col justify-center">
      <div className="lg:flex md:h-full w-full relative lg:pb-10 bg-ellow-300">
        <div className="flex flex-col w-full items-center bg-gren-300 ">
          <div className="flex flex-col items-center w-full mt-10">
            {/* <img
              className="max-w-[225px] max-h-[82px] mb-[20px]"
              src={ClueLogo}
              alt={'Clue Logo'}
            /> */}
            <div className="text-black text-4xl font-semibold font-['Public Sans']">
              {'Start New Plan'}
            </div>
            <p className="flex justify-center items-center text-center text-black text-5xl font-extrabold mb-8">
              {'I want to [....]?'}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <button
                className={`flex flex-col px-5 py-4 bg-white rounded-2xl ${
                  planType === 'Increase Website taps'
                    ? 'border-2 border-cyan-500 focus:bg-cyan-50'
                    : 'border border-cyan-500 focus:bg-white'
                } flex-col items-center inline-flex`}
                onClick={() => {
                  setPlanType('Increase Website taps');
                  console.log('selected', planType);
                  setPlanSelected(true);
                }}
              >
                <div className="flex flex-col justify-center items-center text-center">
                  <p className="flex flex-col text-blue-950 text-[26px] font-semibold mb-4 justify-center leading-tight">
                    {'Increase '}
                    <span className="ml-2 text-cyan-500 text-[26px] font-semibold font-['Public Sans']">
                      {'website taps'}
                    </span>
                  </p>
                  <img
                    className="max-w-[86px] max-h-[92px]"
                    src={Website}
                    alt={'Clue Logo'}
                  />
                </div>
              </button>
              <button
                className={`flex flex-col px-5 py-4 bg-white rounded-2xl ${
                  planType === 'Increase brand awareness'
                    ? 'border-cyan-500 border-2 focus:bg-cyan-50'
                    : 'border border-cyan-500'
                } flex-col items-center inline-flex`}
                onClick={() => {
                  setPlanType('Increase brand awareness');
                  setPlanSelected(true);
                }}
              >
                <div className="flex flex-col justify-center items-center text-center">
                  <p className="flex flex-col text-blue-950 text-[26px] font-semibold mb-4 justify-center leading-tight">
                    {'Increase  '}
                    <span className="ml-2 text-cyan-500 text-[26px] font-semibold font-['Public Sans']">
                      {'brand awareness'}
                    </span>
                  </p>
                  <img
                    className="max-w-[86px] max-h-[92px]"
                    src={Brand}
                    alt={'Clue Logo'}
                  />
                </div>
              </button>
              <button
                className={`flex flex-col px-5 py-4 bg-white rounded-2xl ${
                  planType === 'Increase interaction of my social account'
                    ? 'border-cyan-500 border-2 focus:bg-cyan-50'
                    : 'border border-cyan-500'
                } flex-col items-center inline-flex`}
                onClick={() => {
                  setPlanType('Increase interaction of my social account');
                  setPlanSelected(true);
                }}
              >
                <div className="flex flex-col justify-center items-center text-center">
                  <p className="flex flex-col text-blue-950 text-[26px] font-semibold mb-4 justify-center leading-tight">
                    Increase <br /> Interaction of my
                    <span className="ml-2 text-cyan-500 text-[26px] font-semibold font-['Public Sans']">
                      {' social account'}
                    </span>
                  </p>
                  <img
                    className="max-w-[86px] max-h-[92px]"
                    src={ViewContent}
                    alt={'Clue Logo'}
                  />
                </div>
              </button>
              <button
                className={`flex flex-col px-5 py-4 bg-white rounded-2xl ${
                  planType === 'Increase my followers'
                    ? 'border-cyan-500 border-2 focus:bg-cyan-50'
                    : 'border border-cyan-500'
                } flex-col items-center inline-flex`}
                onClick={() => {
                  setPlanType('Increase my followers');
                  setPlanSelected(true);
                }}
              >
                <div className="flex flex-col justify-center items-center text-center">
                  <p className="flex flex-col text-blue-950 text-[26px] font-semibold mb-4 justify-center leading-tight">
                    {'Increase'}
                    <span className="ml-2 text-cyan-500 text-[26px] font-semibold font-['Public Sans']">
                      {' my followers'}
                    </span>
                  </p>
                  <img
                    className="max-w-[96px] max-h-[96px] mt-4"
                    src={Followers}
                    alt={'Clue Logo'}
                  />
                </div>
              </button>
            </div>
          </div>
          <div className="flex gap-5 ">
            <button
              className="flex  justify-center items-center px-0 lg:px-20 py-3 bg-white border-2 border-rose-500  rounded-lg mt-10 text-center text-rose-500 text-lg font-bold  mb-5"
              onClick={() => {
                navigate(-1);
              }}
            >
              {'Back'}
            </button>
            <button
              className="px-28 md:px-40 lg:px-32 py-3 bg-rose-500 hover:bg-rose-400 rounded-lg mt-10 text-center text-white text-lg font-semibold mb-5"
              onClick={() => {
                selectPlanType();
              }}
            >
              {'Next'}
            </button>
          </div>
        </div>
        <img
          className="hidden md:flex absolute w-[300px] h-[280px] lg:w-[343px] lg:h-[343px] right-0 -rotate-90 bottom-0 -z-10"
          src={YellowCircle}
          alt={'details circles'}
        />
        {/* <div className="md:absolute bottom-2 right-10 justify-center md:justify-end items-center gap-4 inline-flex w-full">
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Terms and Conditions
          </button>
          <div className="w-px h-[31px] relative bg-pink-400" />
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Privacy Policy
          </button>
        </div> */}
      </div>
    </div>
  );
};
export default CreatePlan;
