import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

// Define the props interface for the SplineChart component
interface SplineChartProps {
  engagementRateData: any[];
  // overAllScoreData: [];
}
const SplineChart: React.FC<SplineChartProps> = ({
  engagementRateData
  // overAllScoreData
}) => {
  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false // This hides the toolbar
      }
    },

    colors: ['#ec4899', '#14b8a6'],
    dataLabels: {
      enabled: true
    },
    stroke: {
      curve: 'smooth'
    },
    series: [
      {
        name: 'Engagement Metrics',
        data: engagementRateData // Data should be an array of numbers
      }
      // {
      //   name: 'Overall Score',
      //   data: overAllScoreData
      // }
    ],
    xaxis: {
      type: 'category',
      categories: [
        'Post 1',
        'Post 2',
        'Post 3',
        'Post 4',
        'Post 5',
        'Post 6',
        'Post 7',
        'Post 8',
        'Post 9',
        'Post 10',
        'Post 11',
        'Post 12'
      ]
    },
    yaxis: {
      min: 0, // Set the minimum value for the y-axis
      max: 100 // Set the maximum value for the y-axis
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      }
    }
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={options.series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default SplineChart;
