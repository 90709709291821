import { useState, useEffect } from 'react';
import React from 'react';
import { useDispatch, UseDispatch } from 'react-redux';
import { setInstagramUserData } from '../../../../redux/slices/instagram.data.slice';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import RedwCircle from '../../../../../src/assets/Red_Circle_Left.png';
import ClueLogo from '../../../../../src/assets/Clue-Logo.png';
import InstagramIcon from '../../../../../src/assets/SVG/Instagram-Icon.svg';
import InstaConnectionLoader from '../day-of-awareness/component/days-loader';
import { json } from 'stream/consumers';
const ConnectInstagram = () => {
  const [isInstagramConnected, setInstagramConnected] = useState(false);
  const [showConnectionLoader, setConnectionLoader] = useState(false);
  const [instaUsername, setInstaUsername] = useState<any>('');
  const email = localStorage.getItem('UserEmail');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (localStorage.getItem('InstagramAccessToken')) {
  //     // setInstaAccessToken(localStorage.getItem('InstagramAccessToken') || '');
  //     setInstaUsername(localStorage.getItem('InstagramUsername') || '');
  //     setInstagramConnected(true);
  //   } else setInstagramConnected(false);
  // });

  const handleConnectInsta = async () => {
    setConnectionLoader(true);
    const url = `https://instagram-data1.p.rapidapi.com/user/feed/v2?username=${instaUsername}`;
    // const url = `https://instagram-scraper-api2.p.rapidapi.com/v1.2/posts?username_or_id_or_url=mrbeast`;

    const options = {
      method: 'GET',
      headers: {
        'x-rapidapi-key': 'c2e86b0460mshe1146136755bf0ep1b3b42jsnbfb1f7d5b9f9',
        'x-rapidapi-host': 'instagram-data1.p.rapidapi.com'
      }
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log('first', result);
      if (result.items) {
        const posts = result.items.map((post: any) => {
          return {
            id: post.id,
            caption: post.caption.text,
            like_count: post.like_count,
            comment_count: post.comment_count,
            taken_at: post.taken_at,
            media_type: post.media_type,
            has_shared_to_fb: post.has_shared_to_fb
          };
        });
        console.log('$$$$$$$$$$$$$$$$$$posts', posts);
        localStorage.setItem('InstagramUserPosts', JSON.stringify(posts));
        dispatch(setInstagramUserData({ items: result.items }));
        setInstagramConnected(true);
        const bodyData = {
          email: email,
          instaUserName: instaUsername
        };
        fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        })
          .then(async (res) => {
            console.log('save insta username response', res);
          })
          .catch((err) => {
            console.log('Error updating password', err);
          });
        setConnectionLoader(false);
      } else {
        toast.error(
          'Unable to connect please check you instagram username is correct!!'
        );
        setConnectionLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URI}/user/get-profile-data/${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(async (res) => {
        if (res.ok) {
          const response = await res.json();
          console.log('Insta name fetch reponse');
          setInstaUsername(response.profileData.instaUserName);
          setInstagramConnected(
            response.profileData?.instaUserName ? true : false
          );
        }
      })
      .catch((err) => {
        console.log('Error updating password', err);
      });
  }, []);
  return (
    <div className="flex flex-col h-screen relative lg:pb-10 items-center">
      <div className="flex flex-col items-center w-full mt-5">
        <img
          className="max-w-[225px] max-h-[82px] mb-14"
          src={ClueLogo}
          alt={'Clue Logo'}
        />
        <p className="flex justify-center items-center text-center text-black text-4xl md:text-5xl font-extrabold mb-14">
          {'Connect your Instagram'}
        </p>
      </div>
      <div className="max-w-xl w-full flex flex-col justify-between items-center pt-8 pb-20 border border-[#00CCBB] rounded-lg bg-white">
        <img
          className="flex w-10 h-10 mr-5 mb-5"
          src={InstagramIcon}
          alt={'details circles'}
        />
        <p className="text-neutral-800 text-xl font-bold">
          {'Connect your Instagram.'}
        </p>
        <p className="text-neutral-800 text-lg font-normal mb-5"></p>
        {showConnectionLoader ? <InstaConnectionLoader /> : ''}
        {isInstagramConnected ? (
          <>
            <button className="w-80 h-14 bg-green-500 hover:bg-green-400 rounded-lg text-white text-center  text-sm font-medium">
              Connected as {instaUsername}
            </button>
            <p className="flex justify-center items-center text-center text-black text-2xl my-10 font-semibold">
              {`Account name: ${instaUsername}`}
            </p>
            <button
              className="flex w-80 justify-center items-center px-0 lg:px-10 py-3 bg-rose-500 hover:bg-rose-400 rounded-lg mt-10 text-center text-white text-lg font-bold mb-5"
              onClick={() => {
                navigate('/user-details');
              }}
            >
              Next
            </button>
          </>
        ) : (
          <>
            {/* <FacebookLogin
              appId="1490556441489744"
              children={<div>Connect</div>}
              className="w-80 h-14 bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium"
              onSuccess={(response) => {
                console.log('Login Success!', response);
                console.log('**first', response.accessToken);
                // get long lived token
                fetch(
                  `${process.env.REACT_APP_BACKEND_URI}/auth/get-facebook-long-lived-token/${response.accessToken}`,
                  {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }
                )
                  .then(async (response) => {
                    const data = await response.json();
                    // store insta token in local storage
                    localStorage.setItem(
                      'InstagramAccessToken',
                      data.longLivedToken
                    );
                    localStorage.setItem(
                      'InstagramAccessToken',
                      data.longLivedToken
                    );
                    // to receive the instagram posts we would need facebook page id and instagram connected to that page instgram account id
                    //store meta ids to later fetch posts
                    localStorage.setItem('InstagramUsername', data.username);
                    localStorage.setItem('FacebookPageID', data.pageId);
                    localStorage.setItem(
                      'InstagramUserID',
                      data.instagramAccountId
                    );

                    setInstagramConnected(true);
                    setInstaUsername(data.username);
                    setTimeout(() => {
                      navigate('/user-details');
                    }, 3000);
                  })
                  .catch((error) => {
                    console.log('Error fetching long lived token', error);
                  });
              }}
              onFail={(error) => {
                console.log('Login Failed!', error);
              }}
              onProfileSuccess={(response) => {
                console.log('Get Profile Success!', response);
              }}
            ></FacebookLogin> */}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleConnectInsta();
              }}
              className="flex flex-col justify-center items-center text-center"
            >
              <input
                className="w-80 px-5 relative h-14 bg-white rounded-lg border border-slate-300 mb-5 focus:outline-1 focus:outline-cyan-500"
                placeholder="instagram username"
                value={instaUsername}
                required
                onChange={(e: any) => {
                  setInstaUsername(e.target.value);
                }}
              />
              <button
                className="w-80 h-14 bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium mt-4"
                type="submit"
              >
                Connect
              </button>
              <button
                className="w-80 h-14 bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium mt-4"
                onClick={() => {
                  navigate('/user-details');
                }}
              >
                Skip for now
              </button>
            </form>
          </>
        )}
      </div>
      <div className="w-full flex flex-col justify-center ">
        <img
          className="hidden md:flex absolute w-[400px] h-[380px] lg:w-[380px] lg:h-[330px] right-0 -rotate-90 bottom-0 -z-10"
          src={RedwCircle}
          alt={'details circles'}
        />
        {/* <div className="md:absolute bottom-2 right-10 justify-center md:justify-end items-center gap-4 my-5 md:my-0 inline-flex w-full">
          <button
            className="text-blue-950 text-base font-medium capitalize leading-snug cursor-pointer"
            onClick={() => {
              console.log('i wam clicked');
            }}
          >
            Terms and Conditions
          </button>
          <div className="w-px h-[31px] relative bg-pink-400" />
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Privacy Policy
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ConnectInstagram;
