import React from 'react';

export const updatePlanIDInDb = (planId: string, email: string) => {
  const bodyData = {
    email: email,
    currentPlanID: planId
  };
  fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  })
    .then(async (res) => {
      console.log('save plan id response', res);
    })
    .catch((err) => {
      console.log('Error updating plan id', err);
    });
};
