export const getEngagementRate = (data: []) => {
  try {
    const engagmentRates: any = [];
    console.log('first*******', data);
    data.map((post: any, index) => {
      //handle post.like_count should not be 0 to avoid infinity

      const engagementRate =
        (post.comment_count / (post.like_count === 0 ? 1 : post.like_count)) *
        100;

      engagmentRates.push(Math.ceil(engagementRate));
    });
    return engagmentRates;
  } catch (error) {
    console.log('Error generating engagement rate', error);
  }
};

export const getEngagementRateForTikTok = (data: []) => {
  try {
    const engagmentRates: any = [];
    data.map((post: any, index) => {
      //handle post.like_count should not be 0 to avoid infinity

      console.log(
        post.number_of_comments +
          post.number_of_hearts +
          post.number_of_reposts +
          post.number_of_saves
      );
      const engagementRate =
        ((post.number_of_comments +
          post.number_of_hearts +
          post.number_of_reposts +
          post.number_of_saves) /
          (post.number_of_plays === 0 ? 1 : post.number_of_plays)) *
        100;

      engagmentRates.push(Math.ceil(engagementRate));
    });
    console.log('tiktok', engagmentRates);
    return engagmentRates;
  } catch (error) {
    console.log('Error generating engagement rate', error);
  }
};
