import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/user.slice';
import awarenessDaysReducer from './slices/day.awareness.slice';
import instagramDataReducer from './slices/instagram.data.slice';
const rootReducer = combineReducers({
  user: userReducer,
  days: awarenessDaysReducer,
  instagramData: instagramDataReducer
});

export const store = configureStore({
  reducer: rootReducer
});
