import React, { useState, ChangeEvent } from 'react';
// import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import YellowCircle from '../../../../../assets/Yellow-Circle-Left.png';
import ClueLogo from '../../../../../assets/Clue-Logo.png';
import CustomSelect from '../../add-details/component/CustomSelect';

const ReviewPost = () => {
  const [descriptionData, setDescriptionData] = useState({
    description: '',
    event: '',
    inputtedDay: ''
  });
  const navigate = useNavigate();
  const dropDownData = {
    events: [
      { id: 1, name: 'Graphic' },
      { id: 2, name: 'Carousel' },
      { id: 3, name: 'Image' },
      { id: 4, name: 'Long form video' },
      { id: 5, name: 'A roll' },
      { id: 6, name: 'Lifestyle video' }
    ]
  };
  //   const planId = localStorage.getItem('PlanId');
  //   const addInputDayDetail = async () => {
  //     const bodyData = {
  //       planId: planId,
  //       inputDay: {
  //         description: descriptionData.description,
  //         event: descriptionData.event,
  //         inputtedDay: descriptionData.inputtedDay
  //       },
  //       lastStep: 'input-day'
  //     };
  //     console.log({ bodyData });
  //     if (
  //       !descriptionData.description ||
  //       !descriptionData.event ||
  //       !descriptionData.inputtedDay
  //     ) {
  //       toast.error('Please provide complete details');
  //     } else {
  //       try {
  //         const response = await fetch(
  //           `${process.env.REACT_APP_BACKEND_URI}/plan/update`,
  //           {
  //             method: 'PATCH',
  //             headers: {
  //               'Content-Type': 'application/json'
  //             },
  //             body: JSON.stringify(bodyData)
  //           }
  //         );
  //         if (response.ok) {
  //           const data = await response.json();
  //           console.log({ data });

  //           if (data?.code === 200) {
  //             navigate('/day-awareness');
  //           } else {
  //             throw new Error(data.status);
  //           }
  //         } else {
  //           throw new Error('Unable to connect to Backend');
  //         }
  //       } catch (error: any) {
  //         toast.error(`${error?.message}`);
  //         console.log(error.message);
  //         console.log(error.msg);

  //         // Handle network errors or exceptions
  //         console.error('Error:', error);
  //       }
  //     }
  //   };

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 100) {
      setDescriptionData((prevData: any) => ({
        ...prevData,
        description: inputValue
      }));
    }
  };

  const characterLimit = 100;
  const remainingCharacters =
    characterLimit - descriptionData.description.length;

  return (
    <div className="w-full flex flex-col justify-center">
      <div className="lg:flex h-screen w-full relative lg:pb-10">
        <div className="flex flex-col w-full items-center">
          <div className="flex flex-col items-center w-full mt-5">
            <img
              className="max-w-[225px] max-h-[82px] mb-[20px]"
              src={ClueLogo}
              alt={'Clue Logo'}
            />
            <p className="text-black text-4xl font-medium mb-3">
              {'Review Suggestion'}
            </p>
            <p className="flex justify-center items-center text-center text-black text-5xl font-extrabold mb-14">
              {'Pull summary [....]?'}
            </p>
            <div className="w-full max-w-md px-5 md:px-0">
              <div className="relative">
                <textarea
                  className="relative resize-none w-full px-[25px] h-[150px] pt-5 pb-[15px] rounded-2xl border border-cyan-500 flex-col justify-end items-end gap-[13px] inline-flex mb-5 focus:outline-1 focus:outline-cyan-500 focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500"
                  placeholder="Worem ipsum dolor sit amet, consectetur adipiscing elit."
                  value={descriptionData.description}
                  onChange={handleInputChange}
                  maxLength={characterLimit}
                />
                <p className="absolute bottom-10 right-2 text-center text-black text-xs font-light">
                  {`${descriptionData.description.length}/${remainingCharacters}`}
                </p>
              </div>
              <CustomSelect
                data={dropDownData.events.map((item) => ({
                  id: item.id,
                  name: item.name
                }))}
                placeholder={'Select post format'}
                onSelect={(selectedItem: any) => {
                  console.log('event', selectedItem);
                  setDescriptionData((prevData: any) => ({
                    ...prevData,
                    event: selectedItem.name
                  }));
                }}
              />
              <div className="flex w-full justify-center items-center gap-5">
                <button
                  className="flex justify-center items-center  mt-10 px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold mb-5"
                  onClick={() => {
                    navigate('/processing-data');
                  }}
                >
                  {'Back'}
                </button>
                <button
                  className="flex w-full justify-center items-center px-0 lg:px-28 py-3 bg-rose-500 rounded-lg mt-10 text-center text-white text-lg font-bold mb-5"
                  onClick={() => {
                    // addInputDayDetail();
                    navigate('/content-development');
                  }}
                >
                  {'Next'}
                </button>
              </div>
            </div>
          </div>
        </div>
        <img
          className="hidden md:flex absolute w-[400px] h-[330px] lg:w-[363px] lg:h-[343px] left-0 bottom-0 -z-10"
          src={YellowCircle}
          alt={'details circles'}
        />
        <div className="md:absolute bottom-2 right-10 justify-center md:justify-end items-center gap-4 mb-5 md:mb-0 inline-flex w-full">
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Terms and Conditions
          </button>
          <div className="w-px h-[31px] relative bg-pink-400" />
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Privacy Policy
          </button>
        </div>
      </div>
    </div>
  );
};
export default ReviewPost;
