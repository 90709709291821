import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';

import LandingPage from './pages/landing';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import NotFoundPage from './pages/404';
// import OnboardingLayout from './pages/onboarding-layout';
// import StepOne from './pages/onboarding-layout/pages/step-one';
// import StepTwo from './pages/onboarding-layout/pages/step-two';
import RequireAuth from './components/protectedRouteHandler';
import NewUserLogin from './pages/register/verify-your-email';
import EmailSuccess from './pages/register/email-verified-success';
import ToDo from './pages/home/pages/todo/Todo';
import CreatePlan from './pages/home/pages/create-plan/CreatePlan';
import StrategicCalendar from './pages/home/pages/strategic-date/StrategicCalendar';
import Details from './pages/home/pages/add-details/Details';
import AwarenessDayDetails from './pages/home/pages/day-of-awareness/DayofAwareness';
import PlayVideo from './pages/home/pages/play-video';
import ProcessingData from './pages/home/pages/processing-data/sale-posts';
import ReviewPost from './pages/home/pages/processing-data/review-post';
import EditPost from './pages/home/pages/processing-data/edit-post';
import ContentDvevelopment from './pages/home/pages/content-development';
import ContentPlan from './pages/home/pages/content-plan';
import AnalyzeData from './pages/home/pages/analyze-data';
import ConnectInstagram from './pages/home/pages/connect-instagram';
import ReviewMyContent from './pages/home/pages/review-my-content';
import Layout from './pages/home/logout';
import AskingQuestions from './pages/home/pages/asking-questions';
import EditProfile from './pages/home/pages/edit-profile';
import WeeklyContent from './pages/home/pages/weekly-content-strategy';
import ForgotPassword from './pages/forgot-password/enter-email';
import EmailVerification from './pages/forgot-password/forgot-email-verification';
import CreateNewPassword from './pages/forgot-password/create-new-password';
import Report from './pages/home/pages/reports/Report';
import SelectContentPlan from './pages/home/pages/weekly-content-strategy/content-plans';
import ViewResponse from './pages/home/pages/view-response';
function AppRoutes() {
  return (
    <Routes>
      {/* <Route index element={<LandingPage />} /> */}
      <Route path="/" element={<Navigate to="/create-plan" replace />} />

      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/account-verification" element={<NewUserLogin />} />
      <Route path="/emailsuccess" element={<EmailSuccess />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="check-your-inbox" element={<EmailVerification />} />
      <Route path="create-new-password" element={<CreateNewPassword />} />

      {/* user is required to login and verified to access these routes  */}
      <Route element={<RequireAuth />}>
        <Route
          path="connect-instagram"
          element={
            // <Layout>
            <ConnectInstagram />
            // {/* </Layout> */}
          }
        />
        <Route path="/user-details" element={<Details />} />
        <Route
          path="/home"
          element={
            <Layout>
              <HomePage />
            </Layout>
          }
        />
        <Route
          path="/todo"
          element={
            <Layout>
              <ToDo />
            </Layout>
          }
        />
        <Route
          path="/analyze-my-data"
          element={
            <Layout>
              <AnalyzeData />
            </Layout>
          }
        />
        <Route
          path="/view-content-plan"
          element={
            <Layout>
              <SelectContentPlan />
            </Layout>
          }
        />
        <Route
          path="/create-plan"
          element={
            <Layout>
              <CreatePlan />
            </Layout>
          }
        />
        <Route
          path="/report"
          element={
            <Layout>
              <Report />
            </Layout>
          }
        />
        {/* <Route
          path="/calendar"
          element={
            <Layout>
              <StrategicCalendar />
            </Layout>
          }
        /> */}
        {/* <Route
          path="/day-awareness"
          element={
            <Layout>
              <AwarenessDayDetails />
            </Layout>
          }
        /> */}

        {/* <Route
          path="/play-video"
          element={
            <Layout>
              <PlayVideo />
            </Layout>
          }
        /> */}
        <Route
          path="/asking-questions"
          element={
            <Layout>
              <AskingQuestions />
            </Layout>
          }
        />

        <Route
          path="processing-data"
          element={
            <Layout>
              <ProcessingData />
            </Layout>
          }
        />
        <Route
          path="review-post"
          element={
            <Layout>
              <ReviewPost />
            </Layout>
          }
        />
        <Route
          path="edit-post"
          element={
            <Layout>
              <EditPost />
            </Layout>
          }
        />
        <Route
          path="review-my-content"
          element={
            <Layout>
              <ReviewMyContent />
            </Layout>
          }
        />

        <Route
          path="content-plan"
          element={
            <Layout>
              <ContentPlan />
            </Layout>
          }
        />
        <Route
          path="/content-development"
          element={
            <Layout>
              <ContentDvevelopment />
            </Layout>
          }
        />

        <Route
          path="edit-profile"
          element={
            <Layout>
              <EditProfile />
            </Layout>
          }
        />
        <Route
          path="weekly-content-strategy"
          element={
            <Layout>
              <WeeklyContent />
            </Layout>
          }
        />
        <Route
          path="view-response"
          element={
            <Layout>
              <ReviewMyContent />
            </Layout>
          }
        />

        {/* <Route path="/onboarding" element={<OnboardingLayout />}>
          <Route path="step-one" element={<StepOne />} />
          <Route path="step-two" element={<StepTwo />} />
        </Route> */}
      </Route>
      <Route path="/not-found" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AppRoutes;
