// import { Routes, Route } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { useState } from 'react';
import React from 'react';
import SideNavBar from '../pages/sidebar-nav';
const Layout = ({ children }: any) => {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-2">
        <SideNavBar />
      </div>
      {/* <div className="md:fixed w-full md:w-fit flex justify-end sticky z-50 top-0 md:top-10 bg-white rounded-md p-2 right-0 md:right-10">
        <div
          className="flex flex-col rlative rounded-full w-10 h-10 justify-center items-center cursor-pointer"
          onClick={showNodal}
        >
          <UserCircleIcon className="text-teal-500 w-fit h-fit" />
        </div>
        {open && (
          <div
            className="w-52 bg-white flex flex-col p-2 shadow-xl absolute top-12 space-y-5 rounded-lg"
            onMouseLeave={showNodal}
          >
            <button
              className="px-3 flex h-10 w-full hover:bg-teal-100 justify-start cursor-pointer z-50 items-center text-black rounded-lg text-center text-lg"
              onClick={() => {
                navigate('/edit-profile');
              }}
            >
              <PencilSquareIcon className="w-6 h-6 mr-5 text-red-500" />
              <span>{'Edit'}</span>
            </button>
            <button
              className="px-3 flex h-10 w-full hover:bg-teal-100 justify-start cursor-pointer z-50 items-center text-black rounded-lg text-center text-lg"
              onClick={handleLogout}
            >
              <img
                className="max-w-fit max-h-fit mr-5"
                src={LogoutIcon}
                alt={'Clue Logo'}
              />
              <span>{'Logout'}</span>
            </button>
          </div>
        )}
      </div> */}
      <div className="col-span-10">{children}</div>
    </div>
  );
};
export default Layout;
