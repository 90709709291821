import React, { useState, Dispatch, SetStateAction } from 'react';
import Select, { MultiValue, ActionMeta } from 'react-select';
import { OptionType, DropdownInterfaceProps } from '../../types';
const MultiSelectDropdown: React.FC<DropdownInterfaceProps> = ({
  industryDropDownData,
  selectedIndustry,
  setSelectedIndustry
}) => {
  const handleChange = (
    newValue: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    setSelectedIndustry(newValue as OptionType[]);
  };

  return (
    <div>
      <Select<OptionType, true>
        isMulti
        options={industryDropDownData}
        value={selectedIndustry}
        onChange={handleChange}
        placeholder="Select Industry"
        isSearchable={false}
      />
    </div>
  );
};

export default MultiSelectDropdown;
