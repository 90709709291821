import { useState } from 'react';
import { toast } from 'sonner';
import React from 'react';
import { AddIndustryProps } from '../types';
import { getFollowersCount } from '../../edit-profile/component/getFollowersCount';
const AddIndustry: React.FC<AddIndustryProps> = ({
  setIndustryDropDownData,
  currentPlatform
}) => {
  const [industry, setIndustry] = useState('');
  const getInstagramIndustryData = async () => {
    {
      const url = `https://instagram-data1.p.rapidapi.com/user/feed/v2?username=${industry}`;
      const options = {
        method: 'GET',
        headers: {
          'x-rapidapi-key':
            'c2e86b0460mshe1146136755bf0ep1b3b42jsnbfb1f7d5b9f9',
          'x-rapidapi-host': 'instagram-data1.p.rapidapi.co'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.items) {
          // set industry data
          const industryData =
            localStorage.getItem('InstagramIndustryPosts') || '';
          const industryDataArray =
            industryData.length > 0 ? JSON.parse(industryData) : [];
          const finalArray = result.items.map((post: any) => {
            return {
              id: post.id,
              caption: post.caption.text,
              like_count: post.like_count,
              comment_count: post.comment_count,
              taken_at: post.taken_at
            };
          });
          industryDataArray.push({
            industry: industry,
            followers: await getFollowersCount(industry),
            posts: finalArray
          });

          localStorage.setItem(
            'InstagramIndustryPosts',
            JSON.stringify(industryDataArray)
          );
          setIndustryDropDownData((prev: any) => [
            ...prev,
            { value: industry, label: industry }
          ]);
          toast.info('Industry Added.');
        } else {
          toast.error('Unable to add industry.');
        }
      } catch (error) {
        toast.error('Unable to add industry.');

        console.error(error);
      }
    }
  };
  const getFBIndustryData = async () => {
    const url = `https://facebook-data-api2.p.rapidapi.com/graph/v19.0/${industry}/feed?fields=from%2Cid%2Ccreated_time%2Cmessage%2Cattachments%7Bmedia%2Ctarget%2Ctitle%2Ctype%2Curl%2Csubattachments.limit(100)%7D%2Ccomments.summary(total_count).limit(0)%2Creactions.summary(total_count).limit(0)%2Cshares&limit=12`;

    const options = {
      method: 'GET',
      headers: {
        'x-rapidapi-key': 'f4b5f80ba3mshfb84cb85a80469bp1f2355jsn4dd9a7aa4933',
        'x-rapidapi-host': 'facebook-data-api2.p.rapidapi.com'
      }
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log('result', result);
      if (result.data) {
        const industryData = localStorage.getItem('FBIndustryPosts') || '';
        const industryDataArray =
          industryData.length > 0 ? JSON.parse(industryData) : [];
        const finalArray = result.data.map((post: any) => {
          return {
            id: post.id,
            caption: post?.message || '',
            like_count: post.reactions?.summary?.total_count,
            comment_count: post.comments.count
          };
        });
        const names = industryDataArray.map((post: any) => post.industry);
        if (!names.includes(industry)) {
          industryDataArray.push({
            industry: industry,
            data: finalArray
          });
          localStorage.setItem(
            'FBIndustryPosts',
            JSON.stringify(industryDataArray)
          );
          setIndustryDropDownData((prev: any) => [
            ...prev,
            { value: industry, label: industry }
          ]);
          toast.info('Industry Added.');
        } else {
          toast.info('Industry already added.');
        }
      } else {
        toast.error('Industry Not Found!');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getTikTokIndustryData = async () => {
    const url = `https://tiktok-api6.p.rapidapi.com/user/videos`;
    const bodyData = {
      username: industry
    };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-rapidapi-key': 'f4b5f80ba3mshfb84cb85a80469bp1f2355jsn4dd9a7aa4933',
        'x-rapidapi-host': 'tiktok-api6.p.rapidapi.com'
      },
      body: JSON.stringify(bodyData)
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log('result', result);
      if (result.videos) {
        const industryData = localStorage.getItem('TikTokIndustryPosts') || '';
        const industryDataArray =
          industryData.length > 0 ? JSON.parse(industryData) : [];
        const finalArray = result.videos
          .map((post: any, index: number) => {
            return {
              id: post.video_id,
              caption: post?.description || '',
              number_of_comments: post?.statistics?.number_of_comments,
              number_of_hearts: post?.statistics?.number_of_hearts,
              number_of_plays: post?.statistics?.number_of_plays,
              number_of_reposts: post?.statistics?.number_of_reposts,
              number_of_saves: post?.statistics?.number_of_saves
            };
          })
          .slice(0, 12);
        const names = industryDataArray.map((post: any) => {
          return post.industry;
        });
        if (!names.includes(industry)) {
          industryDataArray.push({
            industry: industry,
            data: finalArray
          });
          localStorage.setItem(
            'TikTokIndustryPosts',
            JSON.stringify(industryDataArray)
          );
          setIndustryDropDownData((prev: any) => [
            ...prev,
            { value: industry, label: industry }
          ]);
          toast.info('TikTok account added.');
        } else {
          toast.info('Industry already added.');
        }
      }
    } catch (error) {
      toast.error('Unable to add tiktok account.');
    }
  };
  return (
    <>
      <input
        placeholder="industry"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setIndustry(e.target.value);
        }}
      ></input>
      <button
        onClick={async () => {
          console.log('currentPlatform', currentPlatform);
          if (currentPlatform === 0) {
            await getInstagramIndustryData();
          } else if (currentPlatform === 1) {
            await getFBIndustryData();
          } else if (currentPlatform === 2) {
            await getTikTokIndustryData();
          }
        }}
      >
        Add
      </button>
    </>
  );
};

export default AddIndustry;
