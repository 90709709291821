import { toast } from 'sonner';
export const getFollowersCount = async (instaUsername: string) => {
  const url = `https://instagram-data1.p.rapidapi.com/user/info?username=${instaUsername}`;

  const options = {
    method: 'GET',
    headers: {
      'x-rapidapi-key': 'c2e86b0460mshe1146136755bf0ep1b3b42jsnbfb1f7d5b9f9',
      'x-rapidapi-host': 'instagram-data1.p.rapidapi.com'
    }
  };

  try {
    const response = await fetch(url, options);
    const result = await response.json();
    console.log('result', result);
    if (result) {
      console.log(
        'result.edge_followed_by.count',
        result.edge_followed_by.count
      );
      return result.edge_followed_by.count;
    } else {
      toast.error(
        'Unable to connect please check you instagram username is correct!'
      );
    }
  } catch (error) {
    console.error(error);
  }
};
