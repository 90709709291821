// import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import React from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'sonner';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SignUpCircles from '../../assets/Sign-Up-Circles.png';
import SignUpImage from '../../assets/Sign-Up-Image.png';
import ClueLogo from '../../assets//Clue-Logo.png';
import GoogleLogin from '../login/google-login';
import { setUser } from '../../redux/slices/user.slice';
interface User {
  email: string;
  fullname: string;
}
function RegisterPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const userEmail = localStorage.getItem('UserEmail');
  const initialValues = {
    fullname: '',
    email: '',
    password: '',
    confirm_password: ''
  };

  const validationSchema = Yup.object().shape({
    fullname: Yup.string().required('Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm password is required')
  });

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    const { fullname, email, password } = values;

    const userValues: User = {
      email,
      fullname
    };
    const requestBOdy = {
      email,
      password,
      fullname
    };
    dispatch(setUser({ user: userValues, token: '' }));

    // form is submitted
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/auth/register`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
            // Add any other headers as needed
          },
          body: JSON.stringify(requestBOdy)
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log({ data });

        if (data?.status == 200) {
          localStorage.setItem('UserEmail', userValues.email);
          navigate('/account-verification');
        } else {
          //an error occured on backend
          throw new Error(data.message);
        }
      } else {
        console.log('errr');

        throw new Error('Unable to connect to backend');
      }
    } catch (error: any) {
      toast.error(`${error?.message}`);
      console.log(error.message);
      console.log(error.msg);

      // Handle network errors or exceptions
      console.error('Error:', error);
    }
  };
  // useEffect(() => {
  //   const email = localStorage.getItem('UserEmail');
  //   fetch(
  //     `${process.env.REACT_APP_BACKEND_URI}/user/check-if-user-details-added/${email}`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     }
  //   )
  //     .then(async (res) => {
  //       console.log('User Add Response', res);
  //       if (res.ok) {
  //         const response = await res.json();
  //         console.log(response.userDetailAdded);
  //         if (response?.userDetailAdded) {
  //           navigate('/todo');
  //         } else {
  //           navigate('/user-details');
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('Error adding user details!', err);
  //     });
  // }, [userEmail]);

  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-3 lg:gap-x-8 relative h-screen">
      <div className="hidden lg:flex w-full h-full relative overflow-hidden">
        <img
          className="absolute w-[643px] h-[743px] left-0 bottom-0"
          src={SignUpCircles}
          alt="Circles"
        />
        <img
          className="w-[549px] h-[760px] left-5 top-8 absolute"
          src={SignUpImage}
          alt="SignUp png"
        />
      </div>
      <div className="flex flex-col w-full justify-center md:items-center px-10 md:px-24">
        <div className="flex justify-start w-full">
          <img
            className="max-w-[225px] max-h-[70px] mb-4"
            src={ClueLogo}
            alt="Clue Logo"
          />
        </div>
        <div className="w-full">
          <p className="text-zinc-500 text-base font-medium">Welcome!</p>
          <p className="text-black text-3xl md:text-4xl font-extrabold mb-4">
            Create an Account
          </p>
        </div>
        <GoogleLogin />
        <div className="flex justify-center items-center space-x-2 w-full mb-4">
          <div className="w-10 md:w-20 h-[2px] bg-slate-200"></div>
          <p className="text-base font-medium">Or, sign up with your email</p>
          <div className="w-10 md:w-20 h-[2px] bg-slate-200"></div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="bg-white w-full">
              <div className="flex flex-col space-y-1 mb-4 w-full">
                <label>Name</label>
                <Field
                  as="input"
                  placeholder="Enter name"
                  className="max-w-full h-[45px] bg-white rounded-lg border border-slate-300 pl-5 outline-1 focus:outline-cyan-500"
                  id="fullname"
                  name="fullname"
                />
                <ErrorMessage
                  name="fullname"
                  component="div"
                  className="text-red-500 text-xs"
                />
              </div>
              <div className="flex flex-col space-y-1 mb-4 w-full">
                <label>Email address</label>
                <Field
                  as="input"
                  placeholder="gilroybworn@gmail.com"
                  className="max-w-full h-[45px] bg-white rounded-lg border border-slate-300 pl-5 outline-1 focus:outline-cyan-500"
                  id="email"
                  name="email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-xs"
                />
              </div>
              <div className="flex flex-col space-y-1 mb-4 w-full">
                <label>Password</label>
                <Field
                  as="input"
                  type="password"
                  placeholder="Enter password"
                  className="max-w-full h-[45px] bg-white rounded-lg border border-slate-300 pl-5 outline-1 focus:outline-cyan-500"
                  id="password"
                  name="password"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-xs"
                />
              </div>
              <div className="flex flex-col space-y-1 mb-4 w-full">
                <label>Confirm Password</label>
                <Field
                  as="input"
                  type="password"
                  placeholder="Enter password"
                  className="max-w-full h-[45px] bg-white rounded-lg border border-slate-300 pl-5 outline-1 focus:outline-cyan-500"
                  id="confirm_password"
                  name="confirm_password"
                />
                <ErrorMessage
                  name="confirm_password"
                  component="div"
                  className="text-red-500 text-xs"
                />
              </div>
              <div className="flex w-full justify-between items-center mb-4">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className=" text-cyan-500 focus:ring-0 focus:outline-0 rounded-sm w-4 h-4 mr-2"
                  />
                  <p className="max-w-full text-black text-xs md:text-sm font-medium leading-tight">
                    I have read and agree to Clue Content{' '}
                    <span className="text-[#06c2c2]">User Agreement</span> and{' '}
                    <span className="text-[#06c2c2]">Privacy Policy</span>
                  </p>
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full h-[45px] bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-lg font-bold mb-4"
              >
                Sign up
              </button>
              <div className="flex w-full justify-start items-start  mb-4">
                <p className="w-[130px] text-black text-sm font-medium leading-tight">
                  I have an account?
                </p>
                <Link
                  to={'/login'}
                  className="text-cyan-500 text-sm font-medium leading-tight"
                >
                  Sign in
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default RegisterPage;
