import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { IndustrySeriesData } from '../types';

interface IndustryDataGraphProps {
  industryDataSeries: IndustrySeriesData[];
}

const StackedLineChart: React.FC<IndustryDataGraphProps> = ({
  industryDataSeries
}) => {
  console.log('graph', industryDataSeries);

  const options: ApexOptions = {
    chart: {
      height: 400,
      type: 'area',
      stacked: false, // Enable stacking
      toolbar: {
        show: false // Hide the toolbar
      }
    },
    colors: ['#14b8a6', '#ec4899', '#06b6d4', '#3b82f6'], // teal-500, rose-500, cyan-500, blue-500 colors
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth' // Use smooth curve
    },
    xaxis: {
      type: 'category', // Change type to 'category'
      categories: [
        'Post 1',
        'Post 2',
        'Post 3',
        'Post 4',
        'Post 5',
        'Post 6',
        'Post 7',
        'Post 8',
        'Post 9',
        'Post 10',
        'Post 11',
        'Post 12'
      ]
    },
    yaxis: {
      min: 0,
      max: 100
    },
    grid: {
      padding: {
        left: 0 // Remove left padding to reduce space between y-axis and the chart
      }
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      }
    }
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={industryDataSeries}
        type="area"
        height={350}
      />
    </div>
  );
};

export default StackedLineChart;
