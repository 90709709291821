import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

// Define the props interface for the SplineChart component
interface SplineChartProps {
  engagementRateData: any;
}
const SplineChart: React.FC<SplineChartProps> = ({ engagementRateData }) => {
  const captions = engagementRateData?.hashtag_suggestions?.map(
    (item: any) => item
  );
  const general_suggestions = engagementRateData?.general_suggestions?.map(
    (item: any) => item
  );
  // console.log('llllllllllllll', engagementRateData);
  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false // This hides the toolbar
      }
    },

    colors: ['#ec4899', '#14b8a6'],
    dataLabels: {
      enabled: true
    },
    stroke: {
      curve: 'smooth'
    },
    series: [
      {
        name: 'Engagement Metrics',
        data: engagementRateData?.engagementRates // Data should be an array of numbers
      },
      {
        name: 'Prediction Scores',
        data: engagementRateData?.overallscore
        // Data should be an array of numbers
      }
    ],
    xaxis: {
      type: 'category',
      categories: [
        'Post 1',
        'Post 2',
        'Post 3',
        'Post 4',
        'Post 5',
        'Post 6',
        'Post 7',
        'Post 8',
        'Post 9',
        'Post 10',
        'Post 11',
        'Post 12'
      ]
    },
    yaxis: {
      min: 0, // Set the minimum value for the y-axis
      max: 100 // Set the maximum value for the y-axis
    },
    tooltip: {
      custom: ({ series, seriesIndex, dataPointIndex }) => {
        const value = series[seriesIndex]?.[dataPointIndex];
        const caption = captions[dataPointIndex];
        const general_suggestion = general_suggestions[dataPointIndex];
        if (seriesIndex === 0) {
          return `<div class="tooltip" style="width:350px;padding: 10px">
                  <span><strong>Engagement Rate: ${value}%</strong></span><br/>
                  <span><strong>Hashtag_Suggestion:</strong> ${caption}</span><br/>
                  <span><strong>General_Suggestion:</strong> ${general_suggestion}</span><br/>
                </div>`;
        } else if (seriesIndex === 1) {
          return `<div class="tooltip" style="width:250px;padding: 10px">
                  <span><strong>Prediction Score: ${value}%</strong></span><br/>
                </div>`;
        }
      }
    }
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={options.series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default SplineChart;
