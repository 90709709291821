import { useState } from 'react';
import CustomSelect from '../add-details/component/CustomSelect';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface Tab {
  name: string;
  href: string;
}

interface TabsProps {
  tabs: Tab[];
  onTabClick: (tabName: string) => void;
}

const Tabs = ({ tabs, onTabClick }: TabsProps) => {
  const [currentTab, setCurrentTab] = useState(tabs[0].name);
  const [descriptionData, setDescriptionData] = useState({
    description: '',
    event: '',
    inputtedDay: ''
  });
  console.log(descriptionData);
  const handleTabClick = (tabName: string) => {
    setCurrentTab(tabName);
    onTabClick(tabName);
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <CustomSelect
          data={tabs.map((item: any) => ({
            id: item.id,
            name: item.name
          }))}
          placeholder={currentTab}
          onSelect={(selectedItem: any) => {
            setDescriptionData((prevData: any) => ({
              ...prevData,
              event: selectedItem.name
            }));
            handleTabClick(selectedItem.name);
          }}
        />
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-1" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                currentTab === tab.name
                  ? 'bg-teal-500 text-white'
                  : 'text-gray-600 hover:text-teal-500 border border-teal-500',
                'rounded-md px-3 py-2 text-sm font-medium'
              )}
              aria-current={currentTab === tab.name ? 'page' : undefined}
              onClick={() => handleTabClick(tab.name)}
            >
              {tab.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Tabs;
