import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

const DropDown = ({
  selectedMonthYear,
  setSelectedMonthYear,
  months,
}: {
  selectedMonthYear: string;
  setSelectedMonthYear: (value: string) => void;
  months: string[];
}) => {
  const currentYear = new Date().getFullYear();
  const [selectedMonth, setSelectedMonth] = useState('All');
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleMonthClick = (month: string) => {
    setSelectedMonth(month);
    setSelectedMonthYear(month === 'All' ? 'All' : `${ month } ${ selectedYear } `);
    setIsOpen(false);
  };

  const incrementYear = () => {
    setSelectedYear((prevYear) => {
      const newYear = prevYear + 1;
      setSelectedMonthYear(`${ selectedMonth } ${ newYear } `);
      return newYear;
    });
  };

  const decrementYear = () => {
    setSelectedYear((prevYear) => {
      const newYear = prevYear - 1;
      setSelectedMonthYear(`${ selectedMonth } ${ newYear } `);
      return newYear;
    });
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          onClick={toggleMenu}
          className="inline-flex min-w-36 justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          {selectedMonthYear}
          <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute right-0 z-10 mt-2 min-w-36 max-h-56 overflow-y-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="flex justify-between items-center px-4 py-2 sticky top-0 bg-white border-b">
            <button onClick={decrementYear} className="text-gray-700 hover:text-gray-900">
              <ChevronDownIcon className="h-5 w-5" />
            </button>
            <span className="text-sm">{selectedYear}</span>
            <button onClick={incrementYear} className="text-gray-700 hover:text-gray-900">
              <ChevronUpIcon className="h-5 w-5" />
            </button>
          </div>
          <div className="py-1">
            {months.map((month) => (
              <button
                key={month}
                onClick={() => handleMonthClick(month)}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              >
                {month}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;