import { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SignUpCircles from '../../../../assets/Sign-In-Circles.png';
import SignUpImage from '../../../../assets/Detail-Page-Image.png';
import ClueLogo from '../../../../assets//Clue-Logo.png';
import CustomSelect from '../add-details/component/CustomSelect';
import { toast } from 'sonner';
import React from 'react';

function CompanyDetails() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    userNamePrefix: '',
    name: '',
    customers: '',
    account: '',
    industry: '',
    preferedTone: '',
    businessSummary: '',
    hearAboutUs: '',
    spacesForFilming: ''
  });
  // const { user }: any = useSelector((state: any) => state.user);
  const userEmail = localStorage.getItem('UserEmail');

  const dropDownData = {
    gender: [
      { id: 1, name: 'Mr' },
      { id: 2, name: 'Ms' },
      { id: 3, name: 'Mrs' },
      { id: 4, name: 'Miss' }
    ],
    customers: [
      { id: 1, name: 'B2B' },
      { id: 2, name: 'B2C' }
    ],
    account: [
      { id: 1, name: 'Personal Brand' },
      { id: 2, name: 'Company Account' }
    ],
    space: [
      { id: 1, name: 'In And Around Town' },
      { id: 2, name: 'At The Office' },
      { id: 3, name: 'At Home' },
      { id: 4, name: 'At My Workshop' },
      { id: 5, name: 'In The Studio' }
    ]
  };
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URI}/user/get-profile-data/${userEmail}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(async (res) => {
        console.log('fetch profile data response', res);
        if (res.ok) {
          const response = await res.json();
          const profileData = response.profileData;
          setUserData((prev: any) => ({
            ...prev,
            ...profileData
          }));
          console.log(userData);
        }
      })
      .catch((err) => {
        console.log('Error fetching profile data', err);
      });
  }, []);
  const updateUserDetails = () => {
    const userDetail = {
      userNamePrefix: userData.userNamePrefix,
      name: userData.name,
      customers: userData.customers,
      account: userData.account,
      industry: userData.industry,
      preferedTone: userData.preferedTone,
      spacesForFilming: userData.spacesForFilming,
      hearAboutUs: userData.hearAboutUs,
      businessSummary: userData.businessSummary,
      userDetailAdded: true
    };

    console.log('user details final', userDetail);

    fetch(`${process.env.REACT_APP_BACKEND_URI}/user/${userEmail}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userDetail)
    })
      .then((res) => {
        console.log('User Add Response', res);
        if (res.ok) {
          toast('Company details updated!');
          navigate('/weekly-content-strategy');
        } else {
          toast.error('Please Provide Complete Info:');
        }
      })
      .catch((err) => {
        console.log('Error adding user details!', err);
      });
  };
  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-8">
      <div className="hidden lg:flex h-full w-full relative">
        <img
          className="absolute w-[743px] h-[693px] left-0 bottom-0"
          src={SignUpCircles}
          alt={'details circles'}
        />
        <img
          className="w-[549px] h-[760px] left-20 top-44 absolute"
          src={SignUpImage}
          alt={'Details PNG'}
        />
      </div>
      <div className="flex flex-col w-full items-center px-10 md:px-24">
        <div className="flex justify-start w-full mt-10">
          <img
            className="max-w-[225px] max-h-[82px] mb-[40px]"
            src={ClueLogo}
            alt={'Clue Logo'}
          />
        </div>
        <div className="w-full">
          <p className="text-zinc-500 text-base font-medium">New User?</p>
          <p className="text-black text-5xl font-extrabold mb-[54px]">
            Company Details
          </p>
          <CustomSelect
            data={dropDownData.gender.map((item) => ({
              id: item.id,
              name: item.name
            }))}
            placeholder={'Mr'}
            onSelect={(selectedItem: any) => {
              setUserData((prevData: any) => ({
                ...prevData,
                userNamePrefix: selectedItem.name
              }));
            }}
            value={
              dropDownData.gender.find(
                (item) => item.name === userData.userNamePrefix
              ) || null
            }
          />
          <input
            className="w-full px-5 relative h-14 bg-white rounded-lg border border-slate-300 mb-5 focus:outline-1 focus:outline-cyan-500"
            placeholder="Name"
            value={userData.name}
            onChange={(e: any) => {
              setUserData((prevData: any) => ({
                ...prevData,
                name: e.target.value
              }));
            }}
          />
          <CustomSelect
            data={dropDownData.customers.map((item) => ({
              id: item.id,
              name: item.name
            }))}
            placeholder={'Customers'}
            onSelect={(item: any) => {
              setUserData((prevData: any) => ({
                ...prevData,
                customers: item.name
              }));
            }}
            value={
              dropDownData.customers.find(
                (item) => item.name === userData.customers
              ) || null
            }
          />
          <CustomSelect
            data={dropDownData.account.map((item) => ({
              id: item.id,
              name: item.name
            }))}
            placeholder={'Account'}
            onSelect={(item: any) => {
              setUserData((prevData: any) => ({
                ...prevData,
                account: item.name
              }));
            }}
            value={
              dropDownData.account.find(
                (item) => item.name === userData.account
              ) || null
            }
          />
          <input
            className="w-full px-5 relative h-14 bg-white rounded-lg border border-slate-300 mb-5 focus:outline-1 focus:outline-cyan-500"
            placeholder="Industry"
            value={userData.industry}
            onChange={(e: any) => {
              setUserData((prevData: any) => ({
                ...prevData,
                industry: e.target.value
              }));
            }}
          />
          <input
            className="w-full px-5 relative h-14 bg-white rounded-lg border border-slate-300 mb-5 focus:outline-1 focus:outline-cyan-500"
            placeholder="Prefered tone"
            value={userData.preferedTone}
            onChange={(e: any) => {
              setUserData((prevData: any) => ({
                ...prevData,
                preferedTone: e.target.value
              }));
            }}
          />
          <CustomSelect
            data={dropDownData.space.map((item) => ({
              id: item.id,
              name: item.name
            }))}
            placeholder={'Where do you normally shoot your content?'}
            onSelect={(item: any) => {
              setUserData((prevData: any) => ({
                ...prevData,
                spacesForFilming: item.name
              }));
            }}
            value={
              dropDownData.space.find(
                (item) => item.name === userData.spacesForFilming
              ) || null
            }
          />
          <input
            className="w-full px-5 relative h-14 bg-white rounded-lg border border-slate-300 mb-5 focus:outline-1 focus:outline-cyan-500"
            placeholder="Hear about us"
            value={userData.hearAboutUs}
            onChange={(e: any) => {
              setUserData((prevData: any) => ({
                ...prevData,
                hearAboutUs: e.target.value
              }));
            }}
          />
          <textarea
            className="w-full px-5 py-2 relative h-24 bg-white rounded-lg border border-slate-300 mb-5 focus:outline-1 focus:outline-cyan-500 focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500 resize-none"
            placeholder="Tell us more about your business, What do you do? What do you offer? What are your values?"
            value={userData.businessSummary}
            onChange={(e: any) => {
              setUserData((prevData: any) => ({
                ...prevData,
                businessSummary: e.target.value
              }));
            }}
          />
          <button
            className="w-full px-5 relative h-14 bg-rose-500 hover:bg-rose-400 rounded-lg mb-8 text-center text-white text-lg font-bold"
            onClick={() => {
              updateUserDetails();
            }}
          >
            {'Update'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CompanyDetails;
