// Function to get tabs for each week
import { toast } from 'sonner';

export const getTabs = (organizedData: any) => {
  const tabs: any = [];
  for (const week in organizedData) {
    tabs.push({
      name: `Week ${week}`,
      href: '#',
      index: parseInt(week) - 1 // Adjust index to start from 1
    });
  }
  return tabs;
};

export function groupByWeek(data: any[], num: number) {
  // Function to get the week number of a given date within its month
  function getWeekNumber(date: Date) {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1); // Start of the month
    const dayOfMonth = date.getDate();
    const weekOffset = Math.floor((dayOfMonth - 1) / 7); // Calculate the week offset within the month
    return weekOffset + 1; // Return week number, starting from 1
  }

  // Function to parse date from string
  function parseDate(dateStr: string) {
    return new Date(dateStr);
  }

  // Function to get day name from date
  function getDayName(date: Date) {
    const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THUR', 'FRI', 'SAT'];
    return daysOfWeek[date.getDay()];
  }

  // Initialize groupedData object
  const groupedData: { [key: number]: any[] } = {};
  let weekNumber = 1;

  // Iterate through the data and group posts by week
  data.forEach((item: any, index: number) => {
    const date = parseDate(item.Date);

    // const weekNumber = getWeekNumber(date);
    const dayName = getDayName(date);
    if (weekNumber < 5) {
      // Initialize the array for the current week if it doesn't exist
      if (!groupedData[weekNumber]) {
        groupedData[weekNumber] = [];
      }

      // Add the post to the current week if it doesn't exceed the limit
      if (groupedData[weekNumber].length < num) {
        if (groupedData[weekNumber].length === 0 && item.Date.includes('Mon')) {
          groupedData[weekNumber].push({
            ...item,
            DayName: dayName
          });
        } else if (groupedData[weekNumber].length !== 0) {
          groupedData[weekNumber].push({
            ...item,
            DayName: dayName
          });
        }
        //
      } else {
        // Optionally handle the case where posts exceed the limit for each week
        weekNumber++;
        if (weekNumber === 5) {
          {
            return;
          }
          groupedData[weekNumber] = [];
          groupedData[weekNumber].push({
            ...item,
            DayName: dayName
          });
          console.warn(`Post limit exceeded for week ${weekNumber}.`);
        }
      }
    }
  });

  return groupedData;
}

export async function saveUpdatedPostsInBackend(
  planId: string,
  lastStep: String,
  updatePosts: any
) {
  console.log({ planId });
  const bodyData = {
    planId: planId,
    lastStep: lastStep,
    weeklyContentStrategyPlan: updatePosts
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URI}/plan/update`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyData)
      }
    );
    console.log('rs', response);
    if (response.ok) {
      toast.info('Post caption updated!');
    } else {
      throw new Error('Unable to connect to Backend');
    }
  } catch (error: any) {
    console.log(error.message);
    console.log(error.msg);
    // Handle network errors or exceptions
    console.error('Error:', error);
  }
}

export async function refreshPost(
  planId: string,
  post: any,
  setRefreshLoader: React.Dispatch<React.SetStateAction<boolean>>
) {
  console.log({ planId });
  console.log(post);
  const bodyData = {
    planId: planId,
    post: post
  };
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URI}/plan/refresh-post`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyData)
      }
    );
    console.log('rs', response);
    if (response.ok) {
      const data = await response.json();
      setRefreshLoader(false);
      return data.caption;
    } else {
      throw new Error('Unable to connect to Backend');
    }
  } catch (error: any) {
    console.log(error.message);
    console.log(error.msg);
    // Handle network errors or exceptions
    console.error('Error:', error);
  }
}
