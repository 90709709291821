import React, { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';

function classNames(...classes: (string | undefined | null | false)[]): string {
  return classes.filter(Boolean).join(' ');
}

const CustomSelect = ({
  data,
  placeholder,
  onSelect,
  value
}: {
  data: { id: number; name: string }[];
  placeholder?: string;
  onSelect: (selectedItem: { id: number; name: string } | null) => void;
  value?: { id: number; name: string } | null;
}) => {
  const [selected, setSelected] = useState<{ id: number; name: string } | null>(
    null
  );

  useEffect(() => {
    setSelected(value || null);
  }, [value]);

  const handleSelect = (selectedItem: { id: number; name: string } | null) => {
    setSelected(selectedItem);
    onSelect(selectedItem);
  };

  return (
    <Listbox value={selected} onChange={handleSelect}>
      {({ open }: { open: boolean }) => (
        <>
          <div className="relative mt-2">
            <Listbox.Button
              className={`flex justify-between items-center relative w-full min-w-fit cursor-default rounded-lg bg-white h-14 px-5 mb-5 text-left ${
                selected?.name ? 'text-slate-800' : 'text-slate-400'
              } ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-500 sm:text-sm md:text-base sm:leading-6`}
            >
              <span className="block truncate min-w-fit">
                {selected ? selected.name : placeholder}
              </span>
              <span className="pointer-events-none inset-y-0 right-0 flex items-center">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400 ml-2"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute top-14 z-10 mt-1 max-h-60 w-full min-w-fit overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {data?.map((item) => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-cyan-500 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={item}
                  >
                    {({
                      selected,
                      active
                    }: {
                      selected: boolean;
                      active: boolean;
                    }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {item.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-cyan-500',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default CustomSelect;
