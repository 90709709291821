import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearUser } from '../../redux/slices/user.slice';
import { useNavigate } from 'react-router-dom';
function HomePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    // Dispatch clearUser action to log the user out
    dispatch(clearUser(undefined));
    localStorage.removeItem('GoogleLoginToken');
    localStorage.removeItem('LoginToken');
    localStorage.removeItem('PlanId');
    navigate('/login');
  };

  return (
    <div>
      HomePage
      <Link to="/login"> Login </Link>
      <button onClick={() => handleLogout()}>logout</button>
    </div>
  );
}

export default HomePage;
