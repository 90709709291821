import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignUpCircles from '../../../assets/Sign-Up-Circles.png';
import ForgotPassImage from '../../../assets/Forgot-Pass-Image.png';
import ClueLogo from '../../../assets/Clue-Logo.png';
import { toast } from 'sonner';

const ForgotPassword = () => {
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();
  const sendResetPasswordMail = () => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URI}/mail/send-reset-password-email/${userEmail}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(async (res) => {
        if (res.ok) {
          const resp = await res.json();
          console.log('resp', resp);
          if (resp.code === 200) {
            localStorage.setItem('UserEmail', userEmail);
            navigate('/check-your-inbox');
          } else {
            toast.error('User with provided email not exists!!');
          }
        }
      })
      .catch((err) => {
        toast.error('Please provide correct email!!');
        console.log('Error sending reset password email', err);
      });
  };

  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-8 relative">
      <div className="hidden lg:flex h-screen w-full relative">
        <img
          className="absolute w-[643px] h-[643px] left-0 bottom-0"
          src={SignUpCircles}
          alt="Circles"
        />
        <img
          className="w-[549px] h-[776px] left-28 top-0 absolute"
          src={ForgotPassImage}
          alt="Sign-In png"
        />
      </div>
      <div className="flex flex-col w-full justify- md:items-center px-10 md:px-24">
        <div className="flex justify-start w-full">
          <img
            className="max-w-[225px] max-h-[82px] mb-[50px] mt-5"
            src={ClueLogo}
            alt="Clue Logo"
          />
        </div>
        <div className="w-full">
          <p className="text-black text-5xl font-extrabold">
            Forgot Password ?
          </p>
          <p className="text-zinc-500 text-base font-medium  mb-[44px] mt-5">
            Please enter your email address associated with your account.
          </p>

          <div className="flex flex-col space-y-1 mb-4 w-full">
            <label>Email</label>
            <input
              type="email"
              placeholder="gilroybworn@gmail.com"
              className="max-w-full h-[45px] bg-white rounded-lg border border-slate-300 pl-5 outline-1 focus:outline-cyan-500"
              id="confirm_password"
              name="confirm_password"
              onChange={(e: any) => {
                setUserEmail(e.target.value);
              }}
            />
          </div>
          <button
            type="submit"
            // disabled={isSubmitting}
            className="w-full h-[45px] bg-[#f55866] hover:bg-rose-400 rounded-lg text-white text-base font-bold mb-4"
            onClick={() => {
              sendResetPasswordMail();
            }}
          >
            Submit
          </button>
          <div
            className="flex justify-start items-center"
            onClick={() => {
              navigate('/login');
            }}
          >
            <p>Go back to</p>
            <a className="text-cyan-500 ml-1 hover:underline hover:cursor-pointer">
              Sign in
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
