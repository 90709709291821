import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InstagaramUserDataStateInterface {
  items: [] | any;
}
const initialState = {
  items: []
};
export const instagramDataSlice = createSlice({
  name: 'instagramData',
  initialState,
  reducers: {
    setInstagramUserData: (
      state: InstagaramUserDataStateInterface,
      action: PayloadAction<{
        items: InstagaramUserDataStateInterface['items'];
      }>
    ) => {
      state.items = action.payload.items;
    }
  }
});
export const { setInstagramUserData } = instagramDataSlice.actions;
export default instagramDataSlice.reducer;
