import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AwarenessDaysInterface {
  id: number;
  image: string;
  name: string;
  date: string;
}

interface DaysAwarenessInterface {
  awarenessDays: AwarenessDaysInterface[];
}

const initialState: DaysAwarenessInterface = {
  awarenessDays: [{ id: 0, image: '', name: '', date: '' }]
};

export const awarenessDaysSlice = createSlice({
  name: 'days',
  initialState,
  reducers: {
    setAwarenessDays: (
      state,
      action: PayloadAction<{ days: AwarenessDaysInterface[] }>
    ) => {
      const { days } = action.payload;
      return { ...state, awarenessDays: days };
    }
  }
});

export const { setAwarenessDays } = awarenessDaysSlice.actions;
export default awarenessDaysSlice.reducer;
