import { useEffect, useState } from 'react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUser } from '../../../../redux/slices/user.slice';
import { toast } from 'sonner';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import ClueLogo from '../../../../assets/Clue-Logo.png';
import CheckMyData from '../../../../assets/SVG/Check-My-Data.svg';
import CreateNewPlan from '../../../../assets/SVG/Create-New-Plan.svg';
import ViewContentPlan from '../../../../assets/SVG/View-Content-Plan.svg';
import WhereILeftOff from '../../../../assets/SVG/Where-I-Left-Off.svg';
import profile from '../../../../assets/side-bar/profile.png';
const navigation = [
  { name: 'Create New Plan', href: '#', icon: CreateNewPlan, current: true },
  {
    name: 'View Content Plan',
    href: '#',
    icon: ViewContentPlan,
    current: false
  },
  { name: 'Check My Data', href: '#', icon: CheckMyData, current: false },
  { name: 'Where I left Off', href: '#', icon: WhereILeftOff, current: false },
  { name: 'Report', href: '#', icon: CreateNewPlan, current: false }
];

const SideNavBar = () => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState();
  const email = localStorage.getItem('UserEmail');
  const [selectedNavItem, setSelectedNavItem] = useState(navigation[0].name); // Initialize with the first navigation item
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state);
  const handleNavigationClick = (itemName: string) => {
    setSelectedNavItem(itemName);
    if (itemName === 'Create New Plan') {
      navigate('/create-plan');
    } else if (itemName === 'View Content Plan') {
      navigate('/view-content-plan');
    } else if (itemName === 'Check My Data') {
      navigate('/analyze-my-data');
    } else if (itemName == 'Where I left Off') {
      whereILeft();
    } else if (itemName == 'Report') {
      navigate('/report');
    }
  };
  const whereILeft = async () => {
    const planId = localStorage.getItem('PlanId');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/plan/last-step/${planId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.ok) {
        const data = await response.json();
        redirectUserToLastStep(data.lastStep);
      } else {
        throw new Error('Unable to connect to Backend');
      }
    } catch (error: any) {
      toast.error(`${error?.message}`);
      console.log(error.message);
      console.log(error.msg);

      // Handle network errors or exceptions
      console.error('Error:', error);
    }
  };
  const redirectUserToLastStep = (lastStep: string) => {
    if (lastStep === 'create-plan') navigate('/create-plan');
    else if (lastStep === 'select-plan-type') navigate('/calendar');
    else if (lastStep === 'input-strategic-date') navigate('/day-awareness');
    else if (lastStep === 'input-plan') navigate('/asking-questions');
    else if (lastStep === 'asking-questions')
      navigate('/weekly-content-strategy');
    else if (lastStep === 'weekly-content-strategy')
      navigate('/content-development');
    else if (lastStep === 'content-development') navigate('/content-plan');
    else if (lastStep === 'content-plan') navigate('/review-my-content');
  };
  const handleLogout = () => {
    console.log('logging out');
    dispatch(clearUser(undefined));
    localStorage.removeItem('LoginToken');
    localStorage.removeItem('InstagramAccessToken');
    localStorage.removeItem('InstagramUsername');
    localStorage.removeItem('PlanId');
    navigate('/login');
  };
  useEffect(() => {
    if (user?.user?.fullname) {
      setUserName(user?.user?.fullname);
    } else {
      console.log('fetching from bCKEND');
      fetch(
        `${process.env.REACT_APP_BACKEND_URI}/user/get-profile-data/${email}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async (res) => {
          if (res.ok) {
            const response = await res.json();
            console.log('fetch profile data response', response);
            setUserName(response.profileData.fullname);
            localStorage.setItem('PlanId', response.profileData.currentPlanID);
          }
        })
        .catch((err) => {
          console.log('Error fetching profile data', err);
        });
    }
    // fetch selected days of awareness
  }, [user]);
  return (
    <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 h-full min-h-screen shadow-xl">
      <div className="flex h-16 shrink-0 items-center">
        <img
          className="max-w-[200px] max-h-[62px] my-[20px]"
          src={ClueLogo}
          alt={'Clue Logo'}
        />
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-5">
              {navigation.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className={`
                        ${
                          item.name === selectedNavItem
                            ? 'bg-rose-500 text-white'
                            : 'hover:text-white hover:bg-rose-500'
                        }
                        group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold`}
                    onClick={() => handleNavigationClick(item.name)}
                  >
                    <img
                      src={item.icon}
                      alt="Icon"
                      className={`${
                        item.name === selectedNavItem
                          ? 'text-white'
                          : 'group-hover:text-white'
                      } h-6 w-6`}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </li>
          <li
            className="mt-auto"
            onClick={() => {
              navigate('/edit-profile');
            }}
          >
            <a
              href="#"
              className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6  rounded-lg"
            >
              <img className="h-10 w-10 " src={profile} alt="" />
              <div className="flex flex-col justify-center items-center">
                <span
                  aria-hidden="true"
                  className="font-semibold font-poppins "
                >
                  {userName}
                </span>
                <span className=" text-[#05b6d3] font-thin font-poppins text-xs">
                  Your profile
                </span>
              </div>
            </a>
          </li>
        </ul>
      </nav>
      <button
        className="flex group justify-center items-center px-0 space-x-2 lg:px-[60px] py-3 bg-white border-2 border-rose-500  rounded-lg text-center text-rose-500 text-lg font-bold hover:bg-rose-500"
        onClick={() => {
          handleLogout();
        }}
      >
        <ArrowUpTrayIcon className="w-5 h-5 text-rose-500 group-hover:text-white rotate-90" />
        <span className="group-hover:text-white">{'Logout'}</span>
      </button>
      <div className="flex justify-center items-center gap-4 mb-5 md:mb-0 flex-col w-full">
        <button className="text-blue-950 text-base font-medium capitalize leading-snug hover:text-[#05b6d3]">
          Terms and Conditions
        </button>
        <div className="w-full h-px relative bg-pink-400" />
        <button className="text-blue-950 text-base font-medium capitalize leading-snug pb-5 hover:text-[#05b6d3]">
          Privacy Policy
        </button>
      </div>
    </div>
  );
};
export default SideNavBar;
